import React, { useRef, useEffect } from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import copyright from "../icons/ph_copyright.svg";
import arrowup from "../icons/arrow-up-right.svg";
// import envelope from "../icons/envelope.svg";
import { gsap } from "gsap";
import "../CSS files/Footer.css";

const Footer = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const socialLinksRefs = useRef([]);

  useEffect(() => {
    const currentRefs = socialLinksRefs.current;
    currentRefs.forEach((el) => {
      if (el) {
        el.addEventListener("mouseenter", handleHover);
        el.addEventListener("mouseleave", handleHoverExit);
      }
    });

    // Cleanup listeners on unmount
    return () => {
      currentRefs.forEach((el) => {
        if (el) {
          el.removeEventListener("mouseenter", handleHover);
          el.removeEventListener("mouseleave", handleHoverExit);
        }
      });
    };
  }, []);

  const handleHover = (event) => {
    const target = event.currentTarget.querySelector(".svg-icons");
    if (target) {
      gsap.to(target, {
        y: -10,
        duration: 0.5, // Smoother duration
        ease: "power2.out", // Smoother easing
      });
    }
  };

  const handleHoverExit = (event) => {
    const target = event.currentTarget.querySelector(".svg-icons");
    if (target) {
      gsap.to(target, {
        y: 0,
        duration: 0.5, // Smoother duration
        ease: "power2.out", // Smoother easing
      });
    }
  };

  return (
    <div>
      <section className="footer-section">
        <span className="lines"></span>

        <div className="eighty">
     
          <div className="footer-text" ref={ref}>
            <a href="mailto:adeolaadekoya11@gmail.com" className="contact-me">
            {/* <img src={envelope} alt="contact arrow" className="svg-envelope" /> */}
              <motion.h3
                initial={{ opacity: 0, y: 20 }}
                animate={{
                  opacity: inView ? 1 : 0,
                  y: inView ? 0 : 20,
                }}
                transition={{ duration: 1, ease: "easeOut" }} // Smoother animation
                className="scramble-text"
              >
                Get in touch
              </motion.h3>
            </a>
          </div>
        </div>

        <div className="flexed-container">
          <span className="lines"></span>

          <div className="last-part">
            <div className="copyright-container">
              <div>
                <img src={copyright} alt="Copyright" className="copyright" />
              </div>
              <span>2024 . Designed and developed by me🪄.</span>
            </div>

            <div className="social-links">
              <a
                href="https://www.linkedin.com/in/adeolaadekoya/"
                rel="noreferrer noopener"
                target="_blank"
                ref={(el) => (socialLinksRefs.current[0] = el)}
              >
                <span>
                  LinkedIn{" "}
                  <img src={arrowup} alt="LinkedIn" className="svg-icons" />
                </span>
              </a>
              <a
                href="https://www.behance.net/adeolaadekoya"
                rel="noreferrer noopener"
                target="_blank"
                ref={(el) => (socialLinksRefs.current[1] = el)}
              >
                <span>
                  Behance{" "}
                  <img src={arrowup} alt="Behance" className="svg-icons" />
                </span>
              </a>
              <a
                href="https://twitter.com/adeolaaa_"
                rel="noreferrer noopener"
                target="_blank"
                ref={(el) => (socialLinksRefs.current[2] = el)}
              >
                <span>
                  X (Twitter){" "}
                  <img src={arrowup} alt="Twitter" className="svg-icons" />
                </span>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Footer;
